exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gold-js": () => import("./../../../src/pages/gold.js" /* webpackChunkName: "component---src-pages-gold-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-silver-js": () => import("./../../../src/pages/silver.js" /* webpackChunkName: "component---src-pages-silver-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-gold-month-template-js": () => import("./../../../src/templates/GoldMonthTemplate.js" /* webpackChunkName: "component---src-templates-gold-month-template-js" */),
  "component---src-templates-silver-month-template-js": () => import("./../../../src/templates/SilverMonthTemplate.js" /* webpackChunkName: "component---src-templates-silver-month-template-js" */)
}

